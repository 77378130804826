<template>
  <div class="contact">
    <top-panel></top-panel>
    <div class="contact">
      <div class="contact--form_section">
        <div class="contact--form_title">Kontaktujte Nás</div>
        <div class="contact--form_title contact--form_title-small contact--form_title-small-gray">Napište nám co máte na srdci a my se Vám co nejdříve ozveme zpět</div>
        <div class="contact--form_container">
          <form class="contact--form" autocomplete="off" novalidate v-on:submit.prevent="sendForm">
            <input class="contact--form_input" type="text" placeholder="Jméno" v-model="nameInput">
            <input class="contact--form_input" type="text" inputmode="numeric" placeholder="Telefon" v-model="phoneInput">
            <input class="contact--form_input" type="text" inputmode="email" placeholder="Email" v-model="emailInput">
            <input class="contact--form_input" type="text" placeholder="Zpráva" v-model="messageInput">
            <button class="contact--form_button">Odeslat</button>
            <input class="contact--form_submit" type="submit">
          </form>
        </div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
import TopPanel from '@/components/top_panel/TopPanel'
import BottomPanel from '@/components/bottom_panel/BottomPanel'

export default {
  name: 'home',
  data () {
    return {
      nameInput: '',
      phoneInput: '',
      emailInput: '',
      messageInput: ''
    }
  },
  components: {
    TopPanel,
    BottomPanel
  },
  methods: {
    sendForm () {
      this.$store.dispatch('sendForm', { name: this.nameInput, phone: this.phoneInput, email: this.emailInput, message: this.messageInput })
    }
  }
}
</script>

<style lang="scss">
  @import 'contact';
</style>
